<template>
  <div></div>
</template>

<script>
const deleteAllCookies = () => {
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}
export default {
  name: 'DataReset',
  beforeMount() {
    const isDevMode = this.$store.state.app.isDeveloperMode
    if (!isDevMode) {
      this.$router.push({ name: this.$ROUTER.ERROR.FORBIDDEN })
      return
    }
    this.$router.push({ name: this.$ROUTER.NAME.HOME }).then(() => {
      deleteAllCookies()
      localStorage.clear()
      sessionStorage.clear()
      this.$store.dispatch('app/setDeveloperMode')
      location.reload()
    })
  },
}
</script>

<style scoped></style>
